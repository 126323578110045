import React from "react";
import LandingTemplate from "@src/components/partials/landing/LandingTemplate";
import LandingForm from "@src/components/partials/landing/LandingForm";
import FreeImg from "./free.png";

export default function Free() {
  const props = {
    image: FreeImg,
    title: "Spend money on teachers, not software.",
    body: "ClassDojo for Districts is 100% free for district partners, so you can spend money on what’s really important: education and teachers. Around 5% of families choose to subscribe to our premium service, which allows us to keep ClassDojo free for you.",
    cta: "Compute my district’s savings",
    Form: (
      <LandingForm
        submitLabel="Compute my district’s savings"
        title="See how much you could save with ClassDojo"
        name="free"
        version="1"
        showNumberOfStudents
      />
    ),
    formTitle: "See how much you could save with ClassDojo",
  };

  return <LandingTemplate {...props} />;
}
